<template>
  <section class="account">
    <PageHeader>
      My Account
    </PageHeader>
    <section class="content">
      <div class="main-info-panel-wrapper">
        <PanelWrapper header-color="var(--background-accept-color)" class="main-info-panel">
          <template v-slot:title>
            My info
          </template>
          <template v-slot:body>
            <AccountForm />
          </template>
        </PanelWrapper>
      </div>
      <div class="small-info-panels">
        <AccountFileColumn
          v-for="(column, i) in fileColumns"
          :key="i"
          :column="column"
          @addFile="addFile"
        />
      </div>
    </section>
  </section>
</template>

<script>
import api from '@/api'
import PanelWrapper from '@/components/PanelWrapper'
import AccountForm from '@/components/AccountForm'
import AccountFileColumn from '@/components/AccountFileColumn'

export default {
  name: 'Account',
  components: {
    PanelWrapper,
    AccountForm,
    AccountFileColumn
  },
  data() {
    return {
      fileColumns: [
        {
          title: 'My Resumes',
          category: 'resume',
          files: []
        },
        {
          title: 'My Cover Letters',
          category: 'coverLetter',
          files: []
        },
        {
          title: 'Other Documents',
          category: 'other',
          files: []
        }
      ]
    }
  },
  async created() {
    await this.getUserFiles()
  },
  methods: {
    resetFiles(category) {
      const columnToReset = this.fileColumns.find(column => column.category === category)
      columnToReset.files = []
      this.getUserFiles(category)
    },
    async getUserFiles(category) {
      const { data } = await api.users.getUserFiles()
      const userFiles = data

      if (!userFiles?.length) return

      if (category) {
        const columnToUpdate = this.fileColumns.find(column => column.category === category)
        const filesForCategory = userFiles.filter(file => file.category === category)

        filesForCategory.forEach((file) => columnToUpdate.files.push(file))

        return
      }

      userFiles.forEach((file) => {
        const [columnForFiles] = this.fileColumns.filter(column => column.category === file.category)

        if (columnForFiles) {
          columnForFiles.files.push(file)
        }
      })
    },
    async addFile(category, file) {
      const { error } = await api.users.addFile(category, file)

      if (!error) {
        this.resetFiles(category)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.account {
  @include default-page-paddings;
}

.content {
  margin: 80px auto 0;
  max-width: 960px;

  @include breakpoint-reverse(medium) {
    margin-left: 0;
  }

  @include breakpoint-reverse(small) {
    margin-top: 40px;
  }
}

.main-info-panel-wrapper {
  width: 100%;
}

.small-info-panels {
  display: grid;
  grid-gap: 34px;
  grid-template-columns: repeat(3, 300px);
  margin-top: 65px;

  @include breakpoint-reverse(medium) {
    grid-template-columns: 1fr;
  }
}
</style>
