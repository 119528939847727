<template>
  <div class="panel-wrapper">
    <div class="panel-header" :style="{ backgroundColor: headerColor }">
      <slot name="title" />
    </div>
    <div class="panel-body">
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanelWrapper',
  props: {
    headerColor: {
      type: String,
      required: false,
      default: 'var(--background-highlight-color)'
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-header {
  border-top-left-radius: var(--border-radius-default);
  border-top-right-radius: var(--border-radius-default);
  padding: 11px 30px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h1);
}

.panel-body {
  border-bottom-right-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);
  background-color: var(--background-secondary-color);
}
</style>
