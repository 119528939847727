<template>
  <div class="account-file-column">
    <div class="column-header">
      <span class="column-title">
        {{ column.title }}
      </span>
    </div>
    <div class="column-body">
      <span class="files-counter">
        {{ column.files.length }} files
      </span>

      <label :for="column.category" class="add-file-label">
        <AppButton
          height="48"
          color="var(--button-primary-color)"
          class="add-file-button"
        >
          <img src="/img/icons/plus.svg" alt="">
        </AppButton>
      </label>
      <v-file-input
        v-show="false"
        :id="column.category"
        accept="application/pdf"
        @change="addFile"
      />

      <div class="files-list">
        <a
          v-for="(file, i) in column.files"
          :key="i"
          class="file-item"
          :href="`${apiURL}/preview/${file.fileId}?token=${userToken}`"
          target="_blank"
        >
          <span class="file-title">{{ file.name }}</span>
          <span class="file-date">{{ formatISOdate(file.addedAt) }}</span>
        </a>
      </div>
      <div v-if="column.files.length > 3">
        <v-icon large color="var(--default-icon-color)">
          mdi-menu-down
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { formatISOdate } from '@/services/dateService'
import Fire from '@/plugins/firebase'

export default {
  name: 'AccountFileColumn',
  props: {
    column: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userToken: null,
      apiURL: process.env.VUE_APP_API_URL
    }
  },
  async created() {
    const currentUser = await Fire.getCurrentUser()
    this.userToken = await currentUser.getIdToken()
  },
  methods: {
    formatISOdate,
    addFile(file) {
      const formData = new FormData()
      formData.append('file', file)
      this.$emit('addFile', this.column.category, formData)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.account-file-column {
  border-radius: var(--border-radius-large);
  background-color: var(--background-secondary-color);
}

.column-header {
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
  padding: 11px 40px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
  text-align: center;
  background-color: var(--background-highlight-color);
}

.column-body {
  padding: 14px 21px 25px;
  text-align: center;

  @include breakpoint-reverse(small) {
    padding: 24px 12px;
  }
}

.files-counter {
  display: block;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-base-sm);
}

.add-file-label {
  display: block;
  margin-top: 13px;
  margin-bottom: 20px;
  cursor: pointer;
}

.add-file-button {
  width: 100%;
  box-shadow: none;
  pointer-events: none;

  &::v-deep span.text {
    height: 24px;
    line-height: 24px;
  }
}

.files-list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 270px;
  gap: 14px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.file-item {
  border: 1px solid var(--primary-border-color);
  border-radius: var(--border-radius-default);
  padding: 15px 16px;
  text-align: left;
  color: var(--text-color);
  cursor: pointer;

  .file-title {
    display: block;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-base-lg);
  }

  .file-date {
    font-size: var(--font-size-sm);
  }
}
</style>
