<template>
  <form class="info-form">
    <div class="main-info">
      <AppInput
        label="First Name:"
        type="text"
        :value.sync="getUser.firstName"
        :error-messages="firstNameErrors"
        is-required
        class="input-first-name"
      />
      <AppInput
        label="Last Name:"
        type="text"
        :value.sync="getUser.lastName"
        :error-messages="lastNameErrors"
        is-required
        class="input-last-name"
      />
      <AppInput
        label="Email for communication:"
        type="email"
        :value.sync="getUser.email"
        :error-messages="emailErrors"
        is-required
        class="input-email"
      />
    </div>
    <div class="generate-id">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <AppInput
            v-model.trim="$v.getUser.username.$model"
            label="Create an username:"
            type="text"
            :error-messages="nameErrors"
            is-required
            class="input-id"
            v-bind="attrs"
            :on="on"
          />
        </template>
        <span>This username will be your unique (anonymous) alias for all activity
          in the UnGhosted platform</span>
      </v-tooltip>
    </div>
    <div class="identification">
      <p class="identification-title">
        Voluntary self-identification <span class="identification-sub">
        Our goal is to build awesome teams and to create a harmonious work
        environments for our job seekers and client partners</span>
      </p>

      <div class="select-list">
        <AppSelect
          :value.sync="getUser.education"
          :items="educationList"
          label="Level of education:"
          class="input-education"
        />
        <AppSelect
          :value.sync="getUser.experience"
          :items="experienceList"
          label="Years of experience:"
          class="input-experience"
        />
        <AppSelect
          :value.sync="getUser.gender"
          :items="genderList"
          label="Gender:"
          class="input-gender"
        />
        <AppSelect
          :value.sync="getUser.race"
          :items="raceList"
          label="Race/ethnicity:"
          class="input-race"
        />
        <AppSelect
          :value.sync="getUser.location"
          :items="locationList"
          label="Location:"
          class="input-location"
        />
        <AppSelect
          :value.sync="getUser.veteranStatus"
          :items="veteranList"
          label="Veteran status:"
          class="input-veteran"
        />
        <AppSelect
          :value.sync="getUser.disability"
          :items="disabilityList"
          label="Disability status:"
          class="input-disability"
        />
      </div>
      <span v-show="error" class="error-message">{{ error }}</span>
    </div>

    <AppButton
      width="165"
      height="34"
      class="save-button"
      dark
      :loading="loading"
      color="var(--button-primary-color)"
      @click="submit"
    >
      Save
    </AppButton>
  </form>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'AccountForm',
  mixins: [validationMixin],
  data() {
    return {
      error: '',
      loading: false,
      educationList: [
        'High School Diploma',
        'Associate`s Degree',
        'Bachelor`s Degree',
        'Master`s Degree',
        'Doctoral Degree',
        'Other'
      ],
      experienceList: ['0 to 1', '1 to 5', '5 to 10', '10 to 20', '20+'],
      genderList: ['Male', 'Female', 'I choose not to disclose'],
      raceList: [
        'Black or African American',
        'American-Indian / Alaskan Native',
        'Asian',
        'Hispanic or Latino',
        'Native Hawaiian or Other Pacific Islander',
        'White (Not of Hispanic Origin)',
        'I choose not to disclose'
      ],
      locationList: [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming'
      ],
      disabilityList: [
        'I have a disability',
        'I don`t have a disability',
        'I choose not to disclose'
      ],
      veteranList: [
        'I am a veteran',
        'I am not a veteran',
        'I choose not to disclose'
      ]
    }
  },
  validations: {
    getUser: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      },
      username: {
        required,
        valid(value) {
          const isValid = /^[A-Za-z0-9!-_]*$/.test(value)
          return isValid
        },
        async isUnique(value) {
          if (value === '') return true

          // TODO refactor (on created save user name in some var and use it)
          const actualUserName = await this.checkActualUsername()
          if (actualUserName === value) return true

          const isUserUnique = await this.checkUsername(value)
          return isUserUnique
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getUser']),
    firstNameErrors() {
      const errors = []
      if (!this.$v.getUser.firstName.$dirty) return errors
      !this.$v.getUser.firstName.required &&
        errors.push('First Name is required')
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.getUser.lastName.$dirty) return errors
      !this.$v.getUser.lastName.required &&
        errors.push('Last Name is required')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.getUser.email.$dirty) return errors
      !this.$v.getUser.email.email && errors.push('Must be valid e-mail')
      !this.$v.getUser.email.required && errors.push('E-mail is required')
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.getUser.username.$dirty) return errors
      !this.$v.getUser.username.required && errors.push('Username is required')
      !this.$v.getUser.username.valid &&
        errors.push(
          'Username must contain only these characters A-Z, a-z, 0-9, !-_'
        )
      if (this.$v.getUser.username.$pending) return errors
      !this.$v.getUser.username.isUnique &&
        errors.push('Username must be unique')
      return errors
    }
  },
  methods: {
    async checkUsername(value) {
      const { data } = await api.users.checkUsername(value)

      return data === 'ok'
    },
    async checkActualUsername() {
      const { data } = await api.users.getUser()

      if (data) return data.username

      return null
    },
    async submit() {
      this.$v.$touch()
      if (this.$v.getUser.$invalid) return
      this.loading = true
      const { error } = await api.users.setUserData(this.getUser)
      if (error) this.error = error.text
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.info-form {
  display: grid;
  padding-bottom: 31px;

  .main-info {
    display: grid;
    grid-gap: 14px;
    grid-row-gap: 10px;
    grid-template-areas:
      "first-name first-name last-name last-name"
      "email email email . ";
    border-bottom: 1px solid var(--secondary-border-color);
    padding: 23px 24px;

    .input-first-name {
      grid-area: first-name;
    }

    .input-last-name {
      grid-area: last-name;
    }

    .input-email {
      grid-area: email;
    }

    @include breakpoint-reverse(small) {
      grid-template-areas:
        "first-name"
        "last-name"
        "email";

      padding: 12px;
    }
  }

  .generate-id {
    border-bottom: 1px solid var(--secondary-border-color);
    padding: 12px 24px 20px;

    @include breakpoint-reverse(small) {
      padding: 12px;
    }

    .input-id::v-deep .label {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-base-lg);
    }
  }

  .identification {
    padding: 16px 24px 32px;

    @include breakpoint-reverse(small) {
      padding: 12px;
    }

    .identification-title {
      display: inline-block;
      margin-bottom: 10px;
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-base-lg);

      .identification-sub {
        font-weight: var(--font-weight-normal);
        font-size: var(--font-size-xsm);
        color: var(--tertiary-text-color);
      }
    }

    .select-list {
      display: grid;
      grid-gap: 14px;
      grid-row-gap: 10px;
      grid-template-areas:
        "education education education experience experience ."
        "gender gender race race race race"
        "location location location . . ."
        "veteran veteran veteran disability disability disability";
      grid-template-columns: repeat(6, 1fr);

      .input-education {
        grid-area: education;
      }

      .input-experience {
        grid-area: experience;
      }

      .input-gender {
        grid-area: gender;
      }

      .input-race {
        grid-area: race;
      }

      .input-location {
        grid-area: location;
      }

      .input-veteran {
        grid-area: veteran;
      }

      .input-disability {
        grid-area: disability;
      }

      @include breakpoint-reverse(small) {
        grid-template-areas:
          "education"
          "experience"
          "gender"
          "race"
          "location"
          "veteran"
          "disability";
        grid-template-columns: 1fr;
      }
    }
  }

  .error-message {
    display: block;
    margin-top: 8px;
    color: var(--error-color);
  }

  .save-button {
    justify-self: center;
    box-shadow: none;

    &::v-deep .text {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-lg);
      line-height: var(--line-height-xs);
      text-shadow: none;
      color: var(--primary-button-text-color);
    }
  }

  ::v-deep .label {
    color: var(--tertiary-text-color);
  }
}
</style>
